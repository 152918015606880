.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.framePage-body .framePage-scroll {
  display: block !important;
  overflow: auto;
}
.imgDialog .detail {
  display: flex;
}
.imgDialog .detail .left {
  width: 150px;
  flex-shrink: 0;
}
.imgDialog .detail .left .el-image {
  display: block;
  width: 100%;
  height: 100%;
}
.imgDialog .detail .right {
  margin-left: 20px;
  flex-grow: 1;
}
.imgDialog .detail .right .el-button {
  float: right;
}
.imgDialog .originalImg {
  margin-top: 20px;
  width: 100%;
}
.imgDialog .originalImg .el-image {
  display: block;
  width: 100%;
}
